import React from 'react';
// components
import Layout from '../components/layout';
import SEO from '../components/seo';
// styles
import styles from './bloodtestguide.module.scss';

const BloodTestGuide = () => {
  return (
    <Layout>
      <SEO
        title="Blood test guide | Vitl"
        description="Feel 100% more often with filler-free, non-GMO nutritional supplements made from the highest quality vitamins & minerals to keep your body functioning its best."
      />
      <section className={styles.section}>
        <div className={styles.container}>
          <div className={styles.contentCenter}>
            <div className={styles.contentWrapper}>
              <iframe
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""
                frameborder="0"
                src="https://www.youtube.com/embed/vf7c2Fq9k3M?rel=0"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default BloodTestGuide;
